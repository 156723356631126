import "./ContactHero.css";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Header";
import Paragraph from "../../Paragraph";
import RallyBicycle from "../../../img/RallyBicycle.svg";
import FlowProductTwo from "../../../img/FlowProductTwo.svg";

const ContactHero = () => {
    return (
        <Container className="my-md-5 my-2 custom-container position-relative">
            <Row className="pt-3 ">
                <Col>
                    <Header size="h5" color="primary" className=" hero-header header-component fw-medium mb-2 mt-5 text-center">
                        Kontakt og support. <br />
                        Vi står klar 24/7.
                    </Header>
                    <Paragraph size="p3" className="fw-lighter text-center">
                    En kaffemaskine. Sofa. Eller! En ny Macbook?
                    </Paragraph>
                </Col>
            </Row>
            <LazyLoadImage
                alt="BILLEDE"
                src={RallyBicycle}
                effect="black-and-white"
                wrapperClassName='deco-imgs contact-hero-bicycle'
                width={162}
            />
            <LazyLoadImage
                alt="BILLEDE"
                src={FlowProductTwo}
                effect="black-and-white"
                wrapperClassName='deco-imgs contact-hero-chair'
                width={162}
            />
        </Container>
    );
}

export default ContactHero;