import ContactForm from '../page-components/ContactPage/ContactForm';
import ContactHero from '../page-components/ContactPage/ContactHero';
import Footer from '../page-components/Footer';
import NavBar from '../page-components/NavBar';

const Contact = () => {
    return (
        <>
            <NavBar navArray={[
                {name: "Hjem", link: "/"},
                {name: "For butikker", link: "/business", active: true}
            ]} 
            />
            
            <ContactHero/>

            <ContactForm/>

            <Footer/>

        </>
    );
}

export default Contact;