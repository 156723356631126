import Header from '../Header';
import Paragraph from '../Paragraph';
import { Row, Col, Container } from 'react-bootstrap';
import Button from '../Button';

import Monthly from '../../img/Monthly.svg';
import Hand from '../../img/Hand.svg';
import MoneyCalendarIconBW from '../../img/MoneyCalendarIconBW.svg';

import './Kickback.css';

const Kickbacks = () => {
  return (
    <Row className='justify-content-center d-flex align-items-center py-5 py-sm-6 mt-7' style={{ backgroundColor: '#FAFAFA' }}>
      <Container className='custom-container my-5'>
        <Header size="h4" className='fw-medium text-center '>

          <span>Forsikret</span><span className='fw-light'> med ét klik</span>
        </Header>
        <Paragraph size="p3" color="primarytextcolor" className="text-center paragraph-muted mb-0 fw-lighter">
          {/* Shop as usual and add security at checkout. 
        It's that simple. */}
          Shop som du plejer og tilføj tryghed ved checkout.<br />
          Så simpelt er det.
        </Paragraph>
        <Row className='pt-5 d-flex justify-content-center'>
          <Col sm={6} xl={3} className='kickback-card-wrapper'>
            <div className="text-center kickback-card position-relative">
              <div className="position-absolute rounded-circle top-image">
                <div className='rounded-circle calendar-icon p-3 m-2 d-flex justify-content-center inner'>
                  <img src={MoneyCalendarIconBW} alt="BILLEDE" />
                </div>
              </div>
              <div className='kickback-card-box white-card d-flex justify-content-center flex-column'>
                <Paragraph fontSize="20px" color="primarytextcolor" className=" mb-1 fw-light">
                  Kickback
                </Paragraph>
                <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted mb-0 fw-lighter">
                  {/* You receive  15% of <br/> all sold coverages */}
                  Du modtager 15% af <br /> alle solgte forsikringer
                </Paragraph>
              </div>
            </div>
          </Col>
          <Col sm={6} xl={3} className='kickback-card-wrapper mt-3 mt-xl-7'>
            <div className="text-center kickback-card position-relative">
              <div className="position-absolute rounded-circle top-image">
                <div className='rounded-circle calendar-icon p-3 m-2 d-flex justify-content-center inner'>
                  <img src={MoneyCalendarIconBW} alt="BILLEDE" />
                </div>
              </div>
              <div className='kickback-card-box white-card d-flex justify-content-center flex-column'>
                <Paragraph fontSize="20px" color="primarytextcolor" className=" mb-1 fw-light">
                  {/* Monthly Payouts */}
                  Gratis 1. måned
                </Paragraph>
                <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted mb-0 fw-lighter">
                  {/* You receive  15% of <br/> all sold coverages */}
                  Tilbyd dine kunder 1<br />måneds gratis dækning
                </Paragraph>
              </div>
            </div>
          </Col>
          <Col sm={6} xl={3} className='kickback-card-wrapper mt-3 mt-xl-7'>
            <div className="text-center kickback-card position-relative">
              <div className="position-absolute rounded-circle top-image">
                <div className='rounded-circle calendar-icon p-3 m-2 d-flex justify-content-center inner'>
                  <img src={MoneyCalendarIconBW} alt="BILLEDE" />
                </div>
              </div>
              <div className='kickback-card-box white-card d-flex justify-content-center flex-column'>
                <Paragraph fontSize="20px" color="primarytextcolor" className=" mb-1 fw-light">
                  {/* No Risk */}
                  Ingen risiko
                </Paragraph>
                <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted mb-0 fw-lighter">
                  {/* We run the entire<br/> service and support.   */}
                  Vi står for alt support<br /> og administration
                </Paragraph>
              </div>
            </div>
          </Col>
          <Col sm={6} xl={3} className='kickback-card-wrapper mt-3'>
            <div className="text-center kickback-card position-relative">
              <div className="position-absolute rounded-circle top-image">
                <div className='rounded-circle calendar-icon p-3 m-2 d-flex justify-content-center inner'>
                  <img src={MoneyCalendarIconBW} alt="BILLEDE" />
                </div>
              </div>
              <div className='kickback-card-box white-card d-flex justify-content-center flex-column'>
                <Paragraph fontSize="20px" color="primarytextcolor" className=" mb-1 fw-light">
                  {/* No Binding */}
                  Ingen Binding
                </Paragraph>
                <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted mb-0 fw-lighter">
                  {/* You are free to cut the<br/> ties at any time. */}
                  Du er fri til at opsige <br /> til enhver tid
                </Paragraph>
              </div>
            </div>
          </Col>
        </Row>
        <span className='d-flex justify-content-center kickback-button pt-2 pt-sm-5'>
          <Button onClick={() => window.location.href = '#signup'}>
            {/* Get me started */}
            Bliv kontaktet
          </Button>
        </span>
      </Container>
    </Row>
  );
}

export default Kickbacks;