import SectionWrapper from "../SectionWrapper";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import './Flow.css';

import { Container, Row, Col } from "react-bootstrap";
import Header from "../Header";
import A9 from "../../img/A9.png";
import AddToCart from "../../img/AddToCartDK.svg";
import Protection from "../../img/AddProtectionDK.svg";
import Checkout from "../../img/CheckoutDK.svg";
import FlowProductTwo from "../../img/FlowProductTwo.svg";
import Paragraph from "../Paragraph";
import MaxWidthWrap from "../MaxWidthWrap";

const Flow = () => {
  return (
    <Row className='justify-content-center d-flex align-items-center py-6 py-sm-6 mt-6' style={{ backgroundColor: '#FAFAFA' }}>
      <Container className="custom-container pt-3 ">
        <Row className=" d-xl-block">
          <Header size='h4' color="primary" className='collage-header fw-light pt-5 pb-4'>

            {/* One-click <span className="fw-bold">insurance</span> */}
            <span className="fw-bold">Forsikret</span> med ét klik

          </Header>
          <MaxWidthWrap center width={540}>
            <Paragraph className='text-center d-flex justify-content-center pb-4 fw-light' size='p3'>

              {/* Shop like normal, add protection at checkout and stay worry free with Frankly Insure.  */}
              Shop som du plejer og tilføj tryghed ved checkout. <br />Så simpelt er det.

            </Paragraph>
          </MaxWidthWrap>
          <div className='flow-row pt-4'>
            <div className="flow-col  d-flex flex-column align-items-center">
              <Header size='h4' color="primary" className='flow-header fw-normal mt-4'>

                {/* Shop like normal */}
                Shop

              </Header>
              <Paragraph className='text-center d-flex justify-content-center mb-0 fw-medium number-text' size='p3'>
                01

              </Paragraph>
              <LazyLoadImage
                alt="BILLEDE"
                src={A9}
                effect="black-and-white"
                wrapperClassName='a9 product-image'
                width={186}
              />
              <LazyLoadImage
                alt="BILLEDE"
                src={AddToCart}
                effect="black-and-white"
                wrapperClassName='w-100 add-to-cart'
              />

            </div>
            <div className="middle  d-flex flex-column align-items-center">
              <LazyLoadImage
                alt="BILLEDE"
                src={FlowProductTwo}
                effect="black-and-white"
                className="second-product-img"
                width={162}
              />

              <div className="flow-col d-flex flex-column align-items-center">


                <Header size='h4' color="primary" className='flow-header fw-normal'>

                  {/* Add protection */}
                  Tilføj forsikring

                </Header>
                <Paragraph className='text-center d-flex justify-content-center mb-0 fw-medium number-text' size='p3'>
                  02

                </Paragraph>
                <LazyLoadImage
                  alt="BILLEDE"
                  src={Protection}
                  effect="black-and-white"
                  wrapperClassName='flow-protection'
                />
              </div>
            </div>
            <div className="flow-col d-flex flex-column align-items-center">
              <Header size='h4' color="primary" className='flow-header fw-normal'>

                {/* Check out with Frankly */}
                Checkout

              </Header>
              <Paragraph className='text-center d-flex justify-content-center mb-0 fw-medium number-text' size='p3'>
                03

              </Paragraph>
              <LazyLoadImage
                alt="BILLEDE"
                src={Checkout}
                effect="black-and-white"
                wrapperClassName='checkout-price-img'
                width={320}
              />
              <LazyLoadImage
                alt="BILLEDE"
                src={FlowProductTwo}
                effect="black-and-white"
                wrapperClassName='checkout-product-image'
                width={162}
              />
            </div>
          </div>
        </Row>

      </Container>
    </Row>
  );
}

export default Flow;