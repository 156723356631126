import NavBar from "../page-components/NavBar";
import TermsSection from "../page-components/TermsSection";
import Footer from "../page-components/Footer";

const Terms = () => {
    return ( 
        <>
            <NavBar
                navArray={[
                    {name: "Hjem", link: "/", active: false},
                    {name: "For butikker", link: "/business", active: false},
                ]}
            />

            <TermsSection/>

            <Footer/>
        </>
     );
}
 
export default Terms;