import Section from "./page-components/Section";
import { Row } from "react-bootstrap";
import "./SectionWrapper.css";
import MaxWidthWrap from "./MaxWidthWrap";

const SectionWrapper = ({
  children,
  className,
  noTop,
  noBottom,
  noLeft,
  noRight
}) => {
  className = (className !== undefined) ? className : "";
  let NoTop, NoBottom, NoLeft, NoRight = " ";
  if(noTop) NoTop = "no-top ";
  if(noBottom) NoBottom = "no-bottom ";
  if(noLeft) NoLeft = "no-left ";
  if(noRight) NoRight = "no-right ";
  return (
    <MaxWidthWrap center width={1160}>
      <Row className={"section-wrapper justify-content-center py-xl-6 py-4  " + NoTop + " " + NoBottom + " " + NoLeft + " " + NoRight + " " + className}>
        {children}
      </Row>
    </MaxWidthWrap>
  );
}
 
export default SectionWrapper;