import { useState } from "react";
import "./ClaimsInput.css"

const ClaimsInput = ({
    name,
    inputName,
    placeholder,
    submission,
    type = "text", //Change this to textarea to increase height and put input at the top of its container!
    toggle = null,
    multiple = false,
    essential = false,
    error = false
}) => {
    const id = name.replace(/\s/g, '');
    const [textArea, setTextArea] = useState("");
    const handleTextArea = (e) => {
        setTextArea(e.target.value);
        submission(e);
    }
    const [slider, setSlider] = useState(true);
    const handleSlider = (e) => {
        setSlider(!slider);
        if (toggle !== null)
            toggle(e);
        else
            submission(e);
    }

    return (
        <div className="claims-input-wrap my-4" >
            <label htmlFor={id} className="claims-input-label">{name}{essential && <span className="red-star">*</span>}</label>
            {type !== "textarea" ?
                type === "slider" ? //slider
                    <div className="d-flex">
                        <label className="frankly-switch" >
                            <input name={inputName} id={name} className="frankly-radio-btn" type="checkbox" value={slider} onChange={handleSlider} />
                            <span className="frankly-slider"></span>
                        </label>
                        <p className="slider-paragraph">{placeholder}</p>
                    </div>
                    :
                    type === "file" ?//file
                        <input name={inputName} id={id} className={error ? "claims-input error-state" : 'claims-input'} type={type} placeholder={placeholder} onChange={submission} multiple={multiple} /> //any input
                        :
                        <input name={inputName} id={id} className={error ? "claims-input error-state" : 'claims-input'} type={type} placeholder={placeholder} onChange={submission} /> //any input
                :
                <textarea name={inputName} id={id} className={error ? "claims-input error-state" : 'claims-input'} placeholder={placeholder} value={textArea} onChange={handleTextArea}></textarea> //textarea
            }

        </div>

    );
}

export default ClaimsInput;