import { useState } from 'react';
import './Signup.css';
import { Row, Col, Container } from "react-bootstrap";
import Header from "../Header";
import Paragraph from "../Paragraph";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import './AppSection.css';
import RallyBicycle from "../../img/RallyBicycle.svg";
import FlowProductTwo from "../../img/FlowProductTwo.svg";
import Button from '../Button';
import axios from 'axios';

const Signup = () => {
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [terms, setTerms] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSignup = (e) => {
    e.preventDefault();
    if (isSubmitted) return;
    setStatus('Sender...');
    setIsSubmitted(true);

    if (!terms) {
      setStatus('Giv dit samtykke for at fortsætte.');
      setIsSubmitted(false);
      return;
    }

    if (!name || !email) {
      setStatus('Navn og email skal udfyldes.');
      setIsSubmitted(false);
      return;
    }

    //Make req object to send to backend
    const req = {
      name: name,
      email: email
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      withCredentials: false
    }

    //Send req to backend
    //console.log(process.env.REACT_APP_API_URL+'/signup');
    axios.post(process.env.REACT_APP_API_URL + '/signup', req, config)
      .then(res => {
        console.log(res);
        setStatus('Tak for at skrive dig op. Dine informationer er nu gemt.');
        setIsSubmitted(true);
      }).catch(err => {
        console.log(err);
        setStatus('Noget gik galt. Prøv igen.');
        setIsSubmitted(false)
      })
  }
  return (
    <Container className='custom-container position-relative mt-6' style={{ overflowX: 'clip' }}>
      <Row id="signup" className='px-0 pb-6 pt-6'>

        {/* Desktop */}

        <div className="d-flex flex-row justify-content-center">
          <div className='d-flex flex-column align-items-center'>
            <Header className='fw-bold' size='h5' color='primary'>
              Frankly Signup
            </Header>
            <Header className='fw-light mt-3' size='h5' color='primary'>
              Tilgængelig snart.
            </Header>
            <Paragraph size='p3' className='py-4 fw-lighter text-center'>

              {/* A coffee machine. Sofa. Or! A new Macbook?  Add insurance for a fixed low monthly price at checkout. */}
              En kaffemaskine. Sofa. Eller! En ny Macbook? <br />  Tilføj forsikring til en fast lav månedlig pris ved checkout.


            </Paragraph>
            <form className='d-flex flex-column align-items-center w-100' onSubmit={handleSignup}>

              <input
                className='signup-text-input'
                placeholder='Email Address'
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />

              <Paragraph size='p3' className='paragraph-muted my-auto'>{status}</Paragraph>
              <Button disabled={isSubmitted} className="signup-button mt-0" type="submit">Sign Up</Button>
            </form>
          </div>
        </div>
      </Row>
      <LazyLoadImage
        alt="BILLEDE"
        src={RallyBicycle}
        effect="black-and-white"
        wrapperClassName='deco-imgs bicycle-img'
        width={162}
      />
      <LazyLoadImage
        alt="BILLEDE"
        src={FlowProductTwo}
        effect="black-and-white"
        wrapperClassName='deco-imgs chair-img'
        width={162}
      />
    </Container>
  );
}

export default Signup;