import './Collage.css'
import SectionWrapper from "../SectionWrapper";
import { Row, Col, Container } from "react-bootstrap";
import Header from '../Header';
import Paragraph from '../Paragraph';
import CollageItem from '../CollageItem';
import CollageTextItem from '../CollageTextItem';
import collage1 from '../../img/collage1.webp';
import collage2 from '../../img/collage2.webp';
import collage6 from '../../img/collage6.webp';
import MoneyCalendarIcon from '../../img/MoneyCalendarIcon.svg';


const Collage = () => {
  
  // const collageItems = [
  //   [
  //     {image: collage1, title: 'Appliance'},
  //     {image: collage2, title: 'Beauty'},
  //   ],
  //   [
  //     {image: collage3, title: 'Electronics'},
  //     {image: collage4, title: 'Mobility'},
  //   ],
  //   [
  //     {image: collage5, title: 'Apparel'},
  //     {image: collage6, title: 'Home Decor'},
  //   ],
  // ]
  const collageItems = [
    [
      {image: MoneyCalendarIcon, title: 'Tilføj tryghed når du shopper', subTitle:'En kaffemaskine. Sofa. Eller! Tilføj forsikring.'},
      {image: collage2, title: 'Wellness'},
    ],
    [
      {image: MoneyCalendarIcon, title: 'Tilføj tryghed når du shopper', subTitle:'En kaffemaskine. Sofa. Eller! Tilføj forsikring.'},
      {image: collage1, title: 'Transport'},
    ],
    [
      {image: MoneyCalendarIcon, title: 'Tilføj tryghed når du shopper', subTitle:'En kaffemaskine. Sofa. Eller! Tilføj forsikring.'},
      {image: collage6, title: 'Bolig'},
    ],
  ]
  
  return (
    <Container className='custom-container pt-6 mt-6'>
      <Row className=''>
        <Header size='h4' color="primary" className='collage-header pt-5 fw-light pb-4 p-md-0'>

          {/* Add protection <span className="fw-bold"> when you shop</span> */}
          Tilføj tryghed når<br/>du shopper
         
        </Header>
        <Paragraph size='p3' className='fw-light text-center mt-3'>
          {/* A coffee machine. Sofa. Or! A new Macbook?  Add insurance. */}
          En kaffemaskine. Sofa. Eller! En ny Macbook?  Tilføj forsikring.
        </Paragraph>

        {/* Desktop */}
        <Col className='d-none d-md-block'>
          <div className="d-flex justify-content-center pt-5">
            <CollageTextItem img image={collageItems[0][0].image} title={collageItems[0][0].title} subTitle={collageItems[0][0].subTitle}/>
            <CollageItem className="middle" img image={collageItems[0][1].image} title={collageItems[0][1].title}/>
            <CollageTextItem img image={collageItems[1][0].image} title={collageItems[1][0].title} subTitle={collageItems[0][0].subTitle}/>
          </div>
          <div className="d-flex justify-content-center">
            <CollageItem image={collageItems[1][1].image} title={collageItems[1][1].title}/>
            <CollageTextItem img className="middle" image={collageItems[2][0].image} title={collageItems[2][0].title} subTitle={collageItems[0][0].subTitle}/>
            <CollageItem image={collageItems[2][1].image} title={collageItems[2][1].title}/>
          </div>
        </Col>

        {/* Mobile */}
        <Col xs={12} className='d-flex flex-column align-items-center d-md-none'>
        <CollageTextItem img image={collageItems[0][0].image} title={collageItems[0][0].title} subTitle={collageItems[0][0].subTitle}/>
          <CollageItem imgClass="w-100 collage-mobile-image" shadow={false} image={collageItems[0][1].image} title={collageItems[0][1].title}/>
          <CollageTextItem img image={collageItems[0][0].image} title={collageItems[0][0].title} subTitle={collageItems[0][0].subTitle}/>
          <CollageItem imgClass="w-100 collage-mobile-image" shadow={false} image={collageItems[1][1].image} title={collageItems[1][1].title}/>
          <CollageTextItem img image={collageItems[0][0].image} title={collageItems[0][0].title} subTitle={collageItems[0][0].subTitle}/>
          <CollageItem imgClass="w-100 collage-mobile-image" shadow={false} image={collageItems[2][1].image} title={collageItems[2][1].title}/>
        </Col>
      </Row>
    </Container>

  );
}

export default Collage;