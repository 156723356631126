import './Different.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import { Row, Col } from 'react-bootstrap';
import SectionWrapper from "../SectionWrapper";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MaxWidthWrap from "../MaxWidthWrap";
import Paragraph from '../Paragraph';
import Header from '../Header';
import Container from 'react-bootstrap/Container';

import collage2 from '../../img/collage2.webp';
import collage6 from '../../img/collage6.webp';
import MoneyCalendarIcon from '../../img/MoneyCalendarIcon.svg';

const Different = () => {
  return (
    <Container className='custom-container my-lg-5 pt-lg-5 different-container'>
      <Header size="h5" color="primary" className="text-center fw-normal pt-5 mt-5 mb-0 ">
        {/* Frankly speaking, <span className="fw-bold">we're flipping the script</span> */}
        Tilføj tryghed når<br />du shopper!
      </Header>
      <Paragraph className='fw-lighter text-center mt-3' size='p4'>En kaffemaskine. Sofa. Eller! En ny Macbook?  Tilføj forsikring.</Paragraph>
      <Row >
        <Col xs={{ span: 12, order: 1 }} xl={{ span: 6, order: 1 }}>
          <div className='text-background p-5 d-flex justify-content-center flex-column g-col-6'>
            <div className='rounded-circle shadow calendar-icon p-3 d-flex justify-content-center'><img src={MoneyCalendarIcon} alt="BILLEDE" /></div>
            <Paragraph className=' text-xl-start fw-light' size='p3'>

              <span className='fw-medium' >Tilføj tryghed når du shopper</span><br /> <span className='fw-lighter' >Traditionel produktforsikring er lidt, ja, du ved. Vi besluttede os for at se, om vi kunne gøre det bedre. </span>
            </Paragraph>
          </div>

        </Col>
        <Col xs={{ span: 12, order: 2 }} xl={{ span: 6, order: 2 }} className='mt-3 mt-xl-0'>
          <LazyLoadImage
            alt="BILLEDE"
            src={collage2}
            effect="black-and-white"
            className='different-image frankly-card w-100'
            wrapperClassName="different-image"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={{ span: 12, order: 2 }} xl={{ span: 6, order: 1 }} className='mt-3 mt-xl-0'>
          <LazyLoadImage
            alt="BILLEDE"
            src={collage6}
            effect="black-and-white"
            className='different-image frankly-card w-100'
            wrapperClassName="different-image"
          />
        </Col>
        <Col xs={{ span: 12, order: 1 }} xl={{ span: 6, order: 2 }} >
          <div className='text-background p-5 d-flex justify-content-center flex-column g-col-6'>
            <div className='rounded-circle shadow calendar-icon p-3 d-flex justify-content-center'><img src={MoneyCalendarIcon} alt="BILLEDE" /></div>
            <Paragraph className='text-xl-start fw-light' size='p3'>

              <span className='fw-medium' >Tilføj tryghed når du shopper</span><br /> <span className='fw-lighter' >Traditionel produktforsikring er lidt, ja, du ved. Vi besluttede os for at se, om vi kunne gøre det bedre. </span>
            </Paragraph>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Different;