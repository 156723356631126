import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SectionWrapper from '../SectionWrapper';
import Header from '../Header';
import Paragraph from '../Paragraph';
import MaxWidthWrap from '../MaxWidthWrap';
import './Integration.css';
import Button from '../Button';

import Shopify from '../../img/Shopify.png';
import WooCommerce from '../../img/WooCommerce.png';
import Magento from '../../img/Magento.png';
import Squarespace from '../../img/Squarespace.png';
import Wix from '../../img/Wix.png';
import { Container } from 'react-bootstrap';

const Integration = () => {
  return ( 
    <Container className='custom-container  pt-sm-3'>
      <Header size="h4" className='fw-medium text-center mt-6 pt-5'>
        {/* Time is precious.<span className='fw-bold'> Integration is key.</span> */}
        Tid er penge.<br/>Hurtig integration er alt.
      </Header>
      <Paragraph className='text-center fw-lighter pt-5' size='p3'>
        {/* Shop as usual and add security at checkout.
        It's that simple.   */}
        Shop som du plejer og tilføj tryghed ved checkout.<br/>
        Så simpelt er det.  
      </Paragraph>
      <Row className='pt-sm-5'>
        <Col className=''>
          <div className="integration-row pe-2">
            <div className="integration-logo-wrap ">
              <LazyLoadImage
                alt={'Shopify'}
                effect="black-and-white"
                src={Shopify}
                className="integration-logo"
                height="110px"
              />
            </div>
            <div className="integration-logo-wrap ">
              <LazyLoadImage
                alt={'WooCommerce'}
                effect="black-and-white"
                src={WooCommerce}
                className="integration-logo"
                height="110px"
              />
            </div>
            <div className="integration-logo-wrap ">
              <LazyLoadImage
                alt={'Magento'}
                effect="black-and-white"
                src={Magento}
                className="integration-logo pt-4"
                height="150px"
              />
            </div>
            <div className="integration-logo-wrap ">
              <LazyLoadImage
                alt={'Squarespace'}
                effect="black-and-white"
                src={Squarespace}
                className="integration-logo"
                height="100px"
              />
            </div>
            {/* <div className="integration-logo-wrap ">
              <LazyLoadImage
                alt={'Wix'}
                effect="black-and-white"
                src={Wix}
                className="integration-logo"
                height="90px"
              />
            </div> */}
          </div>
        </Col>
      </Row>
      {/* <span className='d-flex justify-content-center pt-5'>
        <Button onClick={() => window.location.href = '#signup'}>
          {/* Get me started 
          Bliv kontaktet
        </Button>
      </span> */}
    </Container>
   );
}
 
export default Integration;