import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import Paragraph from './Paragraph';
import './CollageItem.css';

const CollageItem = ({
  image,
  title,
  className,
  shadow = true,
  img, //Img tag instead of LazyLoadImage
  imgClass
}) => {
  if(!image)
    return <p>Image not found</p>
  
  if(!title)
    return <p>Title not found</p>

  if(shadow) {
    shadow = 'shadow'
  } else {
    shadow = ''
  }
  
  if(img) {
    return ( 
      <>
        <div className={`collage-item image-container ${className}`}>
          <img src={image} alt="BILLEDE" className={`${shadow} ${imgClass}`}/>
        </div>
      </>
     );
  }

  return ( 
    <>
      <div className={`collage-item image-container ${className}`}>
        <LazyLoadImage
          alt="BILLEDE"
          src={image}
          effect="black-and-white"
          className={`${shadow} ${imgClass}`}
          threshold={9999}
        />
      </div>
    </>
   );
}
 
export default CollageItem;