import { Col, Container, Row } from "react-bootstrap";
import "./ConditionExpandableSection.css";
import Header from "../../Header";
import Paragraph from '../../Paragraph';
import Accordion from 'react-bootstrap/Accordion';

import RallyBicycle from "../../../img/RallyBicycle.svg";
import FlowProductTwo from "../../../img/FlowProductTwo.svg";
import ExternalLinkIcon from "../../../img/ExternalLinkIcon.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
// import Wording from "../../../pdf/Electronics/Policy wording_Wakam_Frankly_ADT.pdf"
// import IPID from "../../../pdf/Electronics/IPID_Wakam_Frankly_ADT.pdf"

const ConditionExpandableSection = () => {
    
    const toggleCards = [
        {
            heading: "Electronic",
            innerCards: [
                { heading: "Frankly Insure Tryghedspakke", paragraph: "(Police)", pdf: "/pdf/policy/electronics" },
                { heading: "IPID", paragraph: "", pdf: "/pdf/ipid" },
            ]
        },
        // {
        //     heading: "Monitor og skærm",
        //     innerCards: [
        //         { heading: "Frankly Insure Tryghedspakke (Police)", paragraph: "(Police)", pdf: "/pdf/policy/electronics" }
        //     ]
        // }
    ];

    return (
        <Container className='custom-container my-5'>
            <Row className="my-5 position-relative">
                <Col>
                    <Header fontSize="50px" color="primary" className="text-center py-md-4 fw-semibold m-auto">
                        {/* Conditions and policies */}
                        Forsikrings betingelser

                    </Header>
                    <Paragraph size='p3' className='fw-lighter text-center '>
                        En kaffemaskine. Sofa. Eller! En ny Macbook?  Tilføj forsikring <br />til en fast lav månedlig pris ved checkout.
                    </Paragraph>

                </Col>
                <LazyLoadImage
                    alt="BILLEDE"
                    src={RallyBicycle}
                    effect="black-and-white"
                    wrapperClassName='deco-imgs-condition condition-hero-bicycle'
                    width={162}
                />
                <LazyLoadImage
                    alt="BILLEDE"
                    src={FlowProductTwo}
                    effect="black-and-white"
                    wrapperClassName='deco-imgs-condition condition-hero-chair'
                    width={162}
                />
            </Row>
            <Row className="my-5 terms-container p-4 position-relative">
                <Header size="h4" className="my-4 text-center fw-normal">Anmeld din skade her</Header>
                <Paragraph size='p3' className='fw-lighter text-center '>
                    En kaffemaskine. Sofa. Eller! En ny Macbook?
                </Paragraph>
                <Accordion defaultActiveKey="0" >
                    {toggleCards.map((toggleCard, index) => (
                        <Accordion.Item eventKey={index} >
                            <Accordion.Header bsPrefix="accordion-header-button">

                                <Header size="h2" className="my-4">{toggleCard.heading}</Header>

                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                                {toggleCard.innerCards.map((innerCard, index) => (
                                    <a href={innerCard.pdf} className="d-flex align-items-center gap-5 row-item" target="_blank" key={index}>

                                        <span className="fw-lighter" style={{width: '300px'}}>
                                            {innerCard.heading}
                                        </span>
                                        <div className="hr-divider" />
                                        <LazyLoadImage wrapperClassName="external-link-img" src={ExternalLinkIcon} />
                                    </a>))}

                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion >
            </Row>
        </Container>
    );
}

export default ConditionExpandableSection;