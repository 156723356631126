import { Container, Row, Col } from 'react-bootstrap'
import NavBar from '../page-components/NavBar'
import Footer from '../page-components/Footer'
import MpSuccess from '../page-components/MpSuccess'


const MobilePaySuccess = () => {
  return ( 
    <Container className='overflow-hidden' fluid>
      <MpSuccess/>
    </Container>
   );
}
 
export default MobilePaySuccess;