import { Col, Container, Row } from "react-bootstrap";
import "./ClaimsForm.css";
import Header from "../../Header";
import ClaimsInput from "./ClaimsInput";
import Button from "../../Button";
import { useState } from "react";
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import Paragraph from "../../Paragraph";

const ClaimsForm = ({ errorMessages, setErrorMessages }) => {
    //Satet to manage the form fields, status message and more
    const [states, setStates] = useState({
        receiptOpen: false,
        homeAddresse: false,
        fileInputs: {},
        inputs: {},
        status: '',
        isSubmitted: false,
        essentialFields: ['Name', 'Email', 'Phone', 'Address', 'City', 'Zip', 'Explanation', 'Model', 'TimeOfIncident', 'PolicyNumber', 'IncidentAddress', 'IncidentCity', 'IncidentZip','ProductReceipt','DamagedProductImageOrVideo']
    });

    // Function to toggle boolean state values and handle change for sliders
    const toggleState = (stateName, e) => {
        setStates(prev => {
            // Toggle the state
            const updatedState = { ...prev, [stateName]: !prev[stateName] };

            // Update essential fields
            if (stateName === 'homeAddresse' && updatedState.homeAddresse) {
                updatedState.essentialFields = updatedState.essentialFields.filter(field => !['IncidentAddress', 'IncidentCity', 'IncidentZip'].includes(field));
            } else if (stateName === 'homeAddresse' && !updatedState.homeAddresse) {
                updatedState.essentialFields = updatedState.essentialFields.concat(['IncidentAddress', 'IncidentCity', 'IncidentZip']);
            }

            if (stateName === 'receiptOpen' && updatedState.receiptOpen) {
                updatedState.essentialFields = updatedState.essentialFields.concat(['ProductPrice', 'ProductPlaceOfPurchase', 'ProductTimeOfPurchase', 'ProductDescription']);
            } else if (stateName === 'receiptOpen' && !updatedState.receiptOpen) {
                updatedState.essentialFields = updatedState.essentialFields.filter(field => !['ProductPrice', 'ProductPlaceOfPurchase', 'ProductTimeOfPurchase', 'ProductDescription','ProductReceipt'].includes(field));
            }
            console.log(updatedState.essentialFields);

            return updatedState;
        });

        handleChange(e);
    }


    const handleChange = (e) => {
        const { name, value, files } = e.target;
        console.log('files :', files);
        console.log('value :', value);
        console.log('name :', name);

        if (files) {
            // Handle file input change
            setStates(prev => ({
                ...prev,
                fileInputs: {
                    ...prev.fileInputs,
                    [name]: files
                }
            }));
        } else {
            // Handle standard input change
            setStates(prev => ({
                ...prev,
                inputs: {
                    ...prev.inputs,
                    [name]: value
                }
            }));
        }
    }


    const validateForm = () => {
        let errors = {};
        for (let field of states.essentialFields) {
            if ((!states.inputs[field] || states.inputs[field] === '') && (!states.fileInputs[field] || states.fileInputs[field] === '')) {
                errors = { ...errors, [field]: `${field.replace(/([A-Z])/g, ' $1').trim()} cannot be blank` }

            }
        }
        setErrorMessages(errors);

        return Object.entries(errors).length === 0;
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setStates(prev => ({ ...prev, isSubmitted: true }));
        const form = e.target.closest('form');

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
            },
            withCredentials: false
        }

        const requestData = new FormData();

        for (let key in states.fileInputs) {
            if (states.fileInputs[key]) {
                for (let i = 0; i < states.fileInputs[key].length; i++) {
                    requestData.append(key, states.fileInputs[key][i]);
                }
            }
        }

        requestData.append("json", JSON.stringify(states.inputs));

        axios.post(`${process.env.REACT_APP_API_URL}/v1/claims/create-claim`, requestData, config)
            .then(res => {
                console.log(res);
                setStates(prev => ({
                    ...prev,
                    status: <span className="status-green">Tak for at skrive dig op. Dine informationer er nu gemt.</span>
                }));
                form.reset();
            }).catch(err => {
                console.log(err);
                setStates(prev => ({
                    ...prev,
                    status: 'Noget gik galt. Prøv igen.',
                    isSubmitted: false
                }));
            })
    }

    return (
        <Container className="my-md-5 my-2 custom-container position-relative" style={{ zIndex: 999 }}>
            <Row className="pt-3">
                <Col>
                    <div className="claims-form py-md-5 px-md-4 px-2 py-4">
                        <Row>
                            <Col>
                                <Header size="h4" className="fw-medium text-center">
                                    Anmeld din skade her
                                </Header>
                                <Paragraph size='p4' className='fw-lighter mt-3 text-center'>En kaffemaskine. Sofa. Eller! En ny Macbook?</Paragraph>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Accordion defaultActiveKey="0" >
                                    <form className="my-md-5 my-4 p-md-4 p-3" onSubmit={handleSubmit}>

                                        <Accordion.Item eventKey="0" >
                                            <Accordion.Header bsPrefix="accordion-header-button">
                                                <Col>
                                                    <Header size="h3" className="my-4">Personoplysninger</Header>
                                                    <Paragraph size='p3' className='fw-lighter mt-2'>Please fill your information so we can get in touch with you.</Paragraph>
                                                </Col>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="Fulde navn" inputName="Name" placeholder="Jens Jensen" submission={handleChange} error={Object.keys(errorMessages).includes('Name')} />
                                                    </Col>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="Email" inputName="Email" placeholder="Jens_jensen@gmail.com" submission={handleChange} error={Object.keys(errorMessages).includes('Email')} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="Telefonnummer" inputName="Phone" placeholder="(+45) 31424729" submission={handleChange} error={Object.keys(errorMessages).includes('Phone')} />
                                                    </Col>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="Adresse" inputName="Address" placeholder="Østerbrogade 21, 2tv" submission={handleChange} error={Object.keys(errorMessages).includes('Address')} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="By" inputName="City" placeholder="København Ø" submission={handleChange} error={Object.keys(errorMessages).includes('City')} />
                                                    </Col>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="Postnummer" inputName="Zip" placeholder="2100" submission={handleChange} error={Object.keys(errorMessages).includes('Zip')} />
                                                    </Col>
                                                </Row>




                                                <ClaimsInput name="Hvor skete skaden?" inputName="Home" placeholder="Brug hjemmeadresse" type="slider" toggle={(e) => toggleState("homeAddresse", e)} />
                                                <div className={!states.homeAddresse ? "" : "d-none"}>
                                                    <Header size="h3" className="my-4 mt-5 pt-5">Adresse</Header>
                                                    <ClaimsInput essential name="Adresse for hændelsen" inputName="IncidentAddress" placeholder="Østerbrogade 21, 2tv" submission={handleChange} error={Object.keys(errorMessages).includes('IncidentAddress')} />
                                                    <Row>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="Postnummer" inputName="IncidentZip" placeholder="2100" submission={handleChange} error={Object.keys(errorMessages).includes('IncidentZip')} />
                                                        </Col>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="By" inputName="IncidentCity" placeholder="København Ø" submission={handleChange} error={Object.keys(errorMessages).includes('IncidentCity')} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">

                                            <Accordion.Header bsPrefix="accordion-header-button">
                                                <Col>
                                                    <Header size="h3" className="my-4">Ved mistet kvittering</Header>
                                                    <Paragraph size='p3' className='fw-lighter mt-2'>Please fill your information so we can get in touch with you.</Paragraph>
                                                </Col>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="Model og mærke" inputName="Model" placeholder="Fx. MacBook Air 13 M3/8/256 2024 (space grey) Apple" submission={handleChange} error={Object.keys(errorMessages).includes('Model')} />
                                                    </Col>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput name="Serienummer (hvis tilgængeligt)" inputName="SN" placeholder="Fx. W88401231AX eller C02CG123DC79" submission={handleChange} error={Object.keys(errorMessages).includes('SN')} />
                                                    </Col>
                                                </Row>
                                                <ClaimsInput name="Har du mistet din kvittering?" inputName="Receipt" placeholder="Ja" type="slider" toggle={(e) => toggleState("receiptOpen", e)} />
                                                <div className={!states.receiptOpen ? "" : "d-none"}>
                                                    <ClaimsInput essential name="Upload kvittering for produktet" inputName="ProductReceipt" placeholder="Click to choose file - No file chosen" type="file" submission={handleChange} error={Object.keys(errorMessages).includes('ProductReceipt')} />
                                                </div>
                                                <div className={states.receiptOpen ? "" : "d-none"}>
                                                    <ClaimsInput essential name="Prisen på produktet" inputName="ProductPrice" placeholder="Fx. 2999" submission={handleChange} error={Object.keys(errorMessages).includes('ProductPrice')} />
                                                    <Row>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="Hvor er produktet købt" inputName="ProductPlaceOfPurchase" placeholder="Firmanavn eller link til hjemmeside" submission={handleChange} error={Object.keys(errorMessages).includes('ProductPlaceOfPurchase')} />
                                                        </Col>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="Hvornår er produktet købt?" inputName="ProductTimeOfPurchase" placeholder="Tid - DD/MM/ÅÅÅÅ" submission={handleChange} error={Object.keys(errorMessages).includes('ProductTimeOfPurchase')} />
                                                        </Col>
                                                    </Row>
                                                    <ClaimsInput essential name="Beskrivelse af produktet" inputName="ProductDescription" placeholder="Tekst..." type="textarea" submission={handleChange} error={Object.keys(errorMessages).includes('ProductDescription')} />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header bsPrefix="accordion-header-button">
                                                <Col>
                                                    <Header size="h3" className="my-4">Oplysninger om skaden</Header>
                                                    <Paragraph size='p3' className='fw-lighter mt-2'>Please fill your information so we can get in touch with you.</Paragraph>
                                                </Col>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <ClaimsInput essential name="Beskriv hændelsen så præcist som muligt, herunder hvad der skete og hvad er gået i stykker" inputName="Explanation" placeholder="Tekst..." type="textarea" submission={handleChange} error={Object.keys(errorMessages).includes('Explanation')} />

                                                <ClaimsInput name="Har du været udsat for tyveri?" inputName="Theft" placeholder="Ja" type="slider" submission={handleChange} />
                                                <Row>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="Hvornår skete skaden?" inputName="TimeOfIncident" placeholder="Tid - DD/MM/ÅÅÅÅ" submission={handleChange} error={Object.keys(errorMessages).includes('TimeOfIncident')} />
                                                    </Col>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="Policenummer" inputName="PolicyNumber" placeholder="XXXX-XXXX-XXXX" submission={handleChange} error={Object.keys(errorMessages).includes('PolicyNumber')} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput name="Har du tegnet andre relevante forsikringer såsom indboforsikring?" inputName="OtherInsurance" placeholder="Fx. Indbo og ulykkesforsikring." submission={handleChange} error={Object.keys(errorMessages).includes('OtherInsurance')} />
                                                    </Col>
                                                    <Col lg={6} sm={12}>
                                                        <ClaimsInput essential name="Upload billeder eller video af skaden" inputName="DamagedProductImageOrVideo" placeholder="Click to choose file - No file chosen" type="file" multiple={true} submission={handleChange} error={Object.keys(errorMessages).includes('DamagedProductImageOrVideo')} />
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Button className='float-end mr-5 mt-5' disabled={states.isSubmitted}>Anmeld skade</Button>
                                        <p className="w-100 text-center mt-3 px-md-5 d-inline-block">{states.status}</p>

                                    </form>
                                </Accordion>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ClaimsForm;