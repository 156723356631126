const MobilePayCancel = () => {
  return ( 
    <div>
      <h1>MobilePayCancel</h1>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
   );
}
 
export default MobilePayCancel;

