import { Container, Row, Col } from "react-bootstrap";
import Header from "../Header";
import Paragraph from "../Paragraph";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import RallyBicycle from "../../img/RallyBicycle.svg";
import FlowProductTwo from "../../img/FlowProductTwo.svg";
import './TermsSection.css';

const TermsSection = () => {
  return (
    <Container className='custom-container py-5 position-relative'>
      <Header fontSize="50px" color="primary" className="py-md-4 fw-semibold m-auto text-center">
        {/* General terms */}
        Vilkår
      </Header>
      <Paragraph size="p3" className="fw-lighter text-center">
        En kaffemaskine. Sofa. Eller! En ny Macbook?<br/>Tilføj forsikring til en fast lav månedlig pris ved checkout.
      </Paragraph>
      <Row className="my-5">
        <Col className="terms-container px-3 px-md-5 pb-5 ">
          <Row>
            {/* <TermsRow
              title="Introduction"
              content=
              {<>
                Welcome to Frankly Insure, a platform that simplifies insurance for both e-commerce platforms and their customers.
                <br/> By accessing our services, we assume you accept these terms and conditions. Do not continue to use Frankly Insure's services if you do not agree to take all the terms and conditions stated on this page.
              </>}
            /> */}
            <TermsRow
              title="Introduktion"
              content=
              {<>
                Velkommen til Frankly Insure. Vi har skabt en platform designet til at gøre forsikring enklere og smartere for både butikker og kunder. Vores mål er, at levere Danmarks mest ligetil forsikring.               </>}
            />
          </Row>
          <Row>
            {/* <TermsRow
              title="Services"
              content=
              {<>
                Welcome to Frankly Insure, a platform that simplifies insurance for both e-commerce platforms and their customers.
                <br/> By accessing our services, we assume you accept these terms and conditions. Do not continue to use Frankly Insure's services if you do not agree to take all the terms and conditions stated on this page.              
              </>}
            /> */}
            <TermsRow
              title="Services"
              content=
              {<>
                Ved at benytte Frankly Insure accepterer du de vilkår og betingelser, som er anført her og under <Link target="_blank" className="text-decoration-underline" to='/conditions'>forsikringsbetingelser</Link>.
                <br /> Hvis du ikke kan acceptere disse vilkår og betingelser, skal du ikke benytte Frankly Insures tjenester.
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Berettigelse til dækning"
              content=
              {<>
                For at være berettiget til dækning skal følgende kriterier opfyldes:
                <ol className="terms-list" type="a">
                  <li>
                    Du skal være mindst 18 år ved køb af forsikringen.
                  </li>
                  <li>
                    Forsikringen skal købes senest 30 dage efter køb af det pågældende udstyr der ønskes forsikret.
                  </li>
                  <li>
                    Udstyret må ikke være bortkommet, stjålet eller beskadiget før forsikringens startdato.
                  </li>
                  <li>
                    Du skal eje det udstyr, der forsikres, og det må ikke være købt brugt, på auktion eller fra en online-auktionsside.
                  </li>
                  <li>
                    Dit udstyr skal være købt nyt i Danmark.
                  </li>
                  <li>
                    Forsikringsdækning af tilbehør kan kun tilkøbes, hvis du også har købt forsikring af det primære udstyr.
                  </li>
                  <li>
                    Din registrerede adresse skal være i Danmark.
                  </li>
                  <li>
                    Værdien af dit udstyr må ikke overstige den angivne værdi i din forsikringsoversigt.
                  </li>
                </ol>

              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Fortrydelsesret"
              content=
              {<>
                Du kan fortryde dit køb af forsikring indenfor 14 dage fra købstidspunktet.
                <br />
                For at udøve fortrydelsesretten, kontakt os på <a href="mailto:shopper@franklyinsure.com" className="text-decoration-underline">shopper@franklyinsure.com</a> eller ring til +45 25 48 83 14.
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Betaling"
              content=
              {<>
                Du har købt en månedlig aftale. Din forsikring træder i kraft på købstidspunktet og løber i en periode på en (1) måned. Den første måneds forsikring er gratis. Den vil derefter fortsætte i yderligere månedlige perioder, forudsat at du fortsætter med at  betale dine månedlige præmier, når de forfalder.
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Manglende betaling"
              content=
              {<>
                Hvis betalingen ikke kan trækkes, vil vi påminde dig via e-mail og SMS efter 3 dage. Du vil have 21 dage til at betale denne. Sker dette ikke vil du modtage 2 rykkere. I 2. rykker vil det fremgå at forsikringen lukkes ned ved manglende betaling efter yderligere 21 dage.
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Opsigelse"
              content=
              {<>
                Som kunde har du ret til at opsige din forsikring hos Frankly Insure til enhver tid. Når du tegner en police, er du dækket fra det præcise øjeblik og en måned frem. Policen og din dækning fornyes automatisk hver måned ved korrekt indbetaling. Hvis du ønsker at opsige din forsikring, kan det gøres nemt via vores <Link className="text-decoration-underline" to="/contact" target="_blank">kontaktside</Link> eller direkte gennem MobilePay. Ved opsigelse, vil din dækning fortsætte indtil den aktuelle policeperiode udløber.
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Nuværende forsikringer"
              content=
              {<>
                Vær opmærksom på eventuelle eksisterende forsikringer, som dækker det samme som din Frankly Insure-police.
                <br />
                Du kan enten selv opsige sådanne forsikringer ved at kontakte dit forsikringsselskab.
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Klagemuligheder"
              content=
              {<>
                Uenigheder eller klager kan rettes direkte til os via vores <Link className="text-decoration-underline" to="/contact" target="_blank">kontaktside</Link>. Under betingelser og i din police finder du også information om yderligere klageinstanser som Wakam og Forsikringsklagenævnet.
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Tilsyn og Garantifond"
              content=
              {<>
                Frankly Insure overholder retningslinjerne fra Finanstilsynet,
                <br />
                og Wakam er medlem af Garantifonden for forsikringsselskaber.
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Godtgørelse"
              content=
              {<>
                Vores medarbejdere og partnere kan modtage godtgørelse, enten som fast løn eller provision, i forbindelse med salg af forsikringer.
              </>}
            />
          </Row>
          <Row>
            <TermsRow
              title="Særlige tilbud"
              content=
              {<>
                Hvis du har benyttet dig af et særligt tilbud, f.eks. gratis dækning, forbeholder vi os retten til at afslutte tilbuddet med 6 måneders varsel.
              </>}
            />
          </Row>

        </Col>
       
      </Row>
      <LazyLoadImage
          alt="BILLEDE"
          src={RallyBicycle}
          effect="black-and-white"
          wrapperClassName='deco-imgs terms-hero-bicycle'
          width={162}
        />
        <LazyLoadImage
          alt="BILLEDE"
          src={FlowProductTwo}
          effect="black-and-white"
          wrapperClassName='deco-imgs terms-hero-chair'
          width={162}
        />
    </Container>
  );
}

const TermsRow = ({ title, content }) => {
  return (
    <div className="terms-row">
      <div className="mt-5">
        <Header size="h3" color="primary" className="fw-medium d-inline-flex">
          {title}
        </Header>
      </div>
      <Paragraph size="p3" color="primarytextcolor" className="paragraph-muted fw-lighter mb-0 pt-3">
        {content}
      </Paragraph>
    </div>
  );
}


export default TermsSection;