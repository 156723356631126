import { Container, Row } from "react-bootstrap";
import Header from "../Header";
import Paragraph from "../Paragraph";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import './AppSection.css';
import MobileAppSamples from "../../img/MobileAppSamples.png";
import MaxWidthWrap from "../MaxWidthWrap";

const AppSection = () => {
  return ( 
    <>
      {/* <SectionWrapper className="justify-content-center d-none d-xl-flex"> */}
      <Container fluid className="d-none d-xl-block pt-6" style={{backgroundColor:'#fff'}}>
        <div className="d-flex justify-content-center">
        <LazyLoadImage
                alt="BILLEDE"
                src={MobileAppSamples}
                effect="black-and-white"
                wrapperClassName='mobile-app-screens-img'
              />
        </div>
        <Row className="pt-6 d-flex justify-content-center" style={{backgroundColor:'#FAFAFA'}}>
          
            <Header className='mb-4 fw-normal text-center' size='h4' color='primary'>

              {/* The Frankly Insure App */}
              Frankly Insure App


            </Header>
            <Header className='mb-4 fw-normal text-center' size='h4' color='primary'>

              {/* Available soon. */}
              Tilgængelig snart!

            </Header>
            <MaxWidthWrap width={516}>
              <Paragraph size='p3' className='mb-4 fw-lighter text-center'>

                {/* Browse your insurances, track your claim status, and never argue with an insurance agent again. */}
                Saml dine forsikringer, følg med i din skadesproces, og modtag skarpe tilbud. 


              </Paragraph>
            </MaxWidthWrap>
            
          
         
        </Row>
      </Container>
    </>
   );
}
 
export default AppSection;