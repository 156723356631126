import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import "./ContactForm.css";
import Header from "../../Header";
import Paragraph from "../../Paragraph";
import ClaimsInput from "../ClaimsPage/ClaimsInput";
import Button from "../../Button";
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';

const ContactForm = () => {

    const [states, setStates] = useState({
        isSubmitted: false,
        status: "",
        inputs: {},
        essentialFields: ["Name", "Email", "Phone", "Address", "Zip", "Message"]
    });

    const validateForm = () => {
        for (let field of states.essentialFields) {
            if (!states.inputs[field] || states.inputs[field] === '') {
                return false;
            }
        }
        return true;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStates(prevState => ({
            ...prevState,
            inputs: {
                ...prevState.inputs,
                [name]: value
            }
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            setStates(prev => ({ ...prev, status: "Udfyld venligst alle felter" }));
            return;
        }

        setStates(prev => ({ ...prev, isSubmitted: true, status: "Sending..." }));
        const form = e.target.closest("form");

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            withCredentials: false
        }

        let requestData = new FormData(form);

        //Send the formdata to the backend, but for now just make a settimout to simulate a request
        axios.post(`${process.env.REACT_APP_API_URL}/v1/contact/create-submission`, requestData, config)
            .then(res => {
                setStates(prevState => ({
                    ...prevState,
                    status: "Success!"
                }));

            }).catch(err => {
                setStates(prevState => ({
                    ...prevState,
                    status: "Noget gik galt"
                }));
            }
            );
    }


    return (
        <Container className="my-md-5 my-2 custom-container position-relative" style={{ zIndex: 999 }}>
            <Row className="pt-3">
                <Col>
                    <div className="contact-form-container p-md-5 py-5 px-4">

                        <Row>
                            <Col>
                                <Header size="h4" className="fw-medium text-center">
                                    Hvordan kan vi hjælpe?
                                </Header>
                                <Paragraph size='p4' className='fw-lighter mt-3 text-center'>En kaffemaskine. Sofa. Eller! En ny Macbook?</Paragraph>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Accordion defaultActiveKey="0" >
                                    <form className="my-md-5 my-4 p-md-4 p-3" onSubmit={handleSubmit}>
                                        <Accordion.Item eventKey="0" >
                                            <Accordion.Header bsPrefix="accordion-header-button">
                                                <Col>
                                                    <Header size="h3" className="my-4">Person Oplysninger</Header>
                                                    <Paragraph size='p3' className='fw-lighter mt-2'>Please fill your information so we can get in touch with you.</Paragraph>
                                                </Col>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <Row>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="Fulde Navn" inputName="Name" placeholder="Jens Jensen" submission={handleChange} />
                                                        </Col>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="Email" inputName="Email" placeholder="Jens.jensen@gmail.com" submission={handleChange} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="Telefonnummer" inputName="Phone" placeholder="(+45) 31424729" submission={handleChange} />
                                                        </Col>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="Adresse" inputName="Address" placeholder="Østerbrogade 21, 2.tv" submission={handleChange} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="By" inputName="City" placeholder="København" submission={handleChange} />
                                                        </Col>
                                                        <Col lg={6} sm={12}>
                                                            <ClaimsInput essential name="Postnummer" inputName="Zip" placeholder="2100" submission={handleChange} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="1" >
                                            <Accordion.Header bsPrefix="accordion-header-button">
                                                <Col>
                                                    <Header size="h3" className="my-4">Efterspørgsel</Header>
                                                    <Paragraph size='p3' className='fw-lighter mt-2'>Please fill your information so we can get in touch with you.</Paragraph>
                                                </Col>
                                            </Accordion.Header>
                                            <Accordion.Body>

                                                <ClaimsInput type="textarea" essential name="Hvad drejer henvendelsen sig om?" inputName="Message" placeholder="Tekst..." submission={handleChange} />

                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Row className="justify-content-center pt-3">
                                            <div class="hr-divider"></div>
                                            <Col lg={5} sm={12} className="py-3 text-center">
                                                <div>
                                                    <Header size="h3" className="fw-medium py-4">
                                                        {/* Contact for shoppers: */}
                                                        Kontakt for shoppere:
                                                    </Header>
                                                    <Paragraph size="p3" className="fw-light contact-form-paragraph">Mail: Shopper@franklyinsure.com</Paragraph>
                                                    {/* <Paragraph size="p3" className="fw-light contact-form-paragraph">Phone: +45 2548 8314</Paragraph> */}
                                                    <Paragraph size="p3" className="fw-light contact-form-paragraph">Telefon: +45 2548 8314</Paragraph>
                                                </div>
                                            </Col>
                                            <Col lg={1} className="justify-content-center align-items-center d-flex">
                                              <div class="vr-divider"></div>
                                            </Col>
                                            <Col lg={5} sm={12} className="py-3 text-center">
                                                <div>
                                                    <Header size="h3" className="fw-medium py-4">
                                                        {/* Contact for business: */}
                                                        Kontakt for butikker:
                                                    </Header>
                                                    <Paragraph size="p3" className="fw-light contact-form-paragraph">Mail: Merchant@franklyinsure.com</Paragraph>
                                                    {/* <Paragraph size="p3" className="fw-light contact-form-paragraph">Phone: +45 3142 4749</Paragraph> */}
                                                    <Paragraph size="p3" className="fw-light contact-form-paragraph">Telefon: +45 3142 4749</Paragraph>
                                                </div>
                                            </Col>
                                            <div class="hr-divider"></div>
                                        </Row>
                                        <Button disabled={states.isSubmitted} className='d-inline-block m-auto'>Send Message</Button><p className="mt-3 px-md-5 d-inline-block m-auto">{states.status}</p>
                                    </form>
                                </Accordion>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>

        </Container>
    );
}

export default ContactForm;