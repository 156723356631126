import ClaimsForm from "../page-components/ClaimsPage/ClaimsForm";
import ClaimsHero from "../page-components/ClaimsPage/ClaimsHero";
import Footer from "../page-components/Footer";
import NavBar from "../page-components/NavBar";
import AlertDialog from "../page-components/AlertDialog";
import { useState } from "react";

const Claims = () => {

    const [errorMessages, setErrorMessages] = useState({});

    return (
        <>
            <NavBar
                navArray={[
                    { name: "Hjem", link: "/", active: false },
                    { name: "For butikker", link: "/business", active: false },
                ]}
            />

            <ClaimsHero />

            <ClaimsForm errorMessages={errorMessages} setErrorMessages={setErrorMessages}/>

            <Footer />

            <AlertDialog errorMessages={errorMessages} setErrorMessages={setErrorMessages}/>

        </>
    );
}

export default Claims;