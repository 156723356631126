import { LazyLoadImage } from "react-lazy-load-image-component";
import { Col, Container, Row } from "react-bootstrap";

import "./ClaimsHero.css";
import RallyBicycle from "../../../img/RallyBicycle.svg";
import FlowProductTwo from "../../../img/FlowProductTwo.svg";
import Header from "../../Header";
import Paragraph from "../../Paragraph";

const ClaimsHero = () => {
    return (
        <Container className="my-md-5 my-2 custom-container position-relative">
            <Row className="pt-3 ">
                <Col>
                    <Header size="h5" color="primary" className=" hero-header header-component fw-medium mb-2 mt-5 text-center">
                        Vi står altid klar <br />
                        til at hjælpe
                    </Header>
                    <Paragraph size="p3" className="fw-lighter text-center">
                        Vi har gjort det super let at melde en skade og du kan gøre det 24/7. Udfyld formularen og vi vil<br /> behandle sagen hurtigst muligt.
                    </Paragraph>
                </Col>
            </Row>
            <LazyLoadImage
                alt="BILLEDE"
                src={RallyBicycle}
                effect="black-and-white"
                wrapperClassName='deco-imgs claims-hero-bicycle'
                width={162}
            />
            <LazyLoadImage
                alt="BILLEDE"
                src={FlowProductTwo}
                effect="black-and-white"
                wrapperClassName='deco-imgs claims-hero-chair'
                width={162}
            />
        </Container>
    );
}

export default ClaimsHero;