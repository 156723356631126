import './Hero.css';

import Row from 'react-bootstrap/Row';

import Header from '../Header';
import Paragraph from '../Paragraph';
import Button from '../Button';


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import HeroImageOne from '../../img/HeroImageOne.svg';
import HeroImageTwo from '../../img/HeroImageTwo.svg';
import HeroImageThree from '../../img/HeroImageThree.svg';
import MaxWidthWrap from '../MaxWidthWrap';
import { Container } from 'react-bootstrap';


const Hero = ({
  header,
  paragraph,
}) => {
  return (
    <Row className='justify-content-center d-flex align-items-center py-5 py-sm-6' style={{backgroundColor: '#FAFAFA'}}>
      <Container className="my-5 my-2 hero-section custom-container justify-content-center justify-content-xl-start ">
        <Row className='pt-3 d-flex justify-content-center position-relative' style={{ 'overflow-x': 'clip' }}>
          {/* <Col xl={7} className="order-last order-xl-first hero-col-left d-flex flex-column d-md-block justify-content-center"> */}
          <Header size="h5" color="primary" className="hero-header text-center fw-light mb-4 mt-4 mt-md-2 margin-top-small">

            {/* <span className="fw-bold">Cover your online purchases </span>in one click  */}
            <span className="fw-bold">Slip bekymringerne  <br /></span> tilføj forsikring når du  <br />shopper online

          </Header>
          <MaxWidthWrap width={561} className='mb-4 d-flex justify-content-center flex-column align-items-center'>
            <Paragraph size='p3' className='fw-light text-center text-margin'>

              {/* From gaming rigs to living room <br className='d-sm-none'/> chandeliers, we've got your back. <br className='d-sm-none'/> Insurance? Frankly, it's never been simpler.  */}
              En kaffemaskine. Sofa. Eller! En ny Macbook? Tilføj forsikring til en fast lav månedlig pris ved checkout.
            </Paragraph>
            <Button signUp className='mt-4 px-4 d-block mx-xl-0'>Sign up</Button>
          </MaxWidthWrap>
          <LazyLoadImage
            alt="BILLEDE"
            src={HeroImageOne}
            effect="black-and-white"
            wrapperClassName=' hero-images hero-img-one position-absolute'
          />
          <LazyLoadImage
            alt="BILLEDE"
            src={HeroImageTwo}
            effect="black-and-white"
            wrapperClassName='hero-images hero-img-two position-absolute'
          />
          <LazyLoadImage
            alt="BILLEDE"
            src={HeroImageThree}
            effect="black-and-white"
            wrapperClassName='hero-images hero-img-three position-absolute'
          />
        </Row>
      </Container>
    </Row>
  );
}

export default Hero;