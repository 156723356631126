import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import Paragraph from './Paragraph';
import './CollageItem.css';

const   CollageTextItem = ({
  image,
  title,
  subTitle,
  className,
  shadow = true,
  img, //Img tag instead of LazyLoadImage
  imgClass
}) => {
  if(!image)
    return <p>Image not found</p>
  
  if(!title)
    return <p>Title not found</p>

  if(shadow) {
    shadow = 'shadow'
  } else {
    shadow = ''
  }
  
  if(img) {
    return ( 
      <>
        <div className={`collage-item image-container text-collage-item justify-content-center align-items-start flex-column ${className}`}>
          <div className='rounded-circle shadow image-container-text-collage mx-3 mt-lg-4 mx-md-2 mx-lg-5'>
            <img src={image} alt="BILLEDE" className={` ${imgClass}`}/>
          </div>
          <div className='mt-3 px-3 px-md-2 py-md-2 px-lg-5 py-lg-3'>
            <Paragraph className='paragraph-secondary-color text-black' size='p4'>{title}</Paragraph>
            <Paragraph className='paragraph-secondary-color fw-light text-black' size='p4'>{subTitle}</Paragraph>
          </div>
        </div>
      </>
     );
  }

  return ( 
    <>
      <div className={`collage-item image-container ${className}`}>
        <LazyLoadImage
          alt="BILLEDE"
          src={image}
          effect="black-and-white"
          className={`${shadow} ${imgClass}`}
          threshold={9999}
        />
        <div className='collage-item-text'>
          <Paragraph className='paragraph-secondary-color' size='p4'>{title}</Paragraph>
        </div>
      </div>
    </>
   );
}
 
export default CollageTextItem;